var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"mb-6 mt-6 p-0",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"mt-0 dataTables_wrapper"},[_c('v-data-table',{staticClass:"systems-parameter",attrs:{"headers":_vm.headers,"items":_vm.data,"item-key":"id","group-desc":true,"group-by":"group","sort-desc":true,"items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:"header.name",fn:function({ header, items }){return [_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.expandAll.apply(null, arguments)}}},[_vm._v("mdi-"+_vm._s(_vm.isExpandedNew ? 'unfold-more-horizontal' : 'unfold-less-horizontal')+" ")]),_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"group.header",fn:function({
                  toggle,
                  group,
                  isOpen,
                  headers,
                  items,
                }){return [_c('td',{staticClass:"pl-0 no-background"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('v-btn',{ref:group,attrs:{"data-open":isOpen,"icon":""},on:{"click":toggle}},[_c('v-icon',[_vm._v("mdi-"+_vm._s(isOpen ? 'chevron-down' : 'chevron-up'))])],1),_c('span',{staticClass:"font-weight-boldest"},[_vm._v(_vm._s(_vm.$moment(group * 1000).format('DD/MM/YYYY'))+" ")])],1)])]),_c('td',[_c('span',{staticClass:"summary"},[_vm._v(" "+_vm._s(_vm.summaryNumber(items, 'duration'))+" "),_c('span',{staticClass:"unit"},[_vm._v("phút")])])]),_c('td',[_c('span',{staticClass:"summary"},[_vm._v(" "+_vm._s(_vm.summaryNumber(items, 'burnedCalorie'))+" "),_c('span',{staticClass:"unit"},[_vm._v("kcal")])])]),_c('td')]}},{key:"item",fn:function({ item, expand, isExpanded }){return [_c('tr',[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(" "+_vm._s(item.duration)+" "),_c('span',{staticClass:"unit"},[_vm._v("phút")])]),_c('td',[_vm._v(" "+_vm._s(Math.round(item.burnedCalorie))+" "),_c('span',{staticClass:"unit"},[_vm._v("kcal")])]),_c('td',{attrs:{"colspan":"1"}},[_vm._v(_vm._s(item.note))])])]}}])}),_c('pagination',{attrs:{"paging":_vm.paging}})],1)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }